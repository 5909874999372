import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { availableLocales } from 'services/EnrollmentForm';
import Registration, { DisabledKeys } from './Registration';
import RegistrationValidation from './RegistrationValidation';
import { UserRegistration } from './UserRegistration';
import Content from '../content/Content';
import TransferPatientHistory from './TransferPatientHistory';
import { seletorUserToRegisterIsStaffOnly, UserToRegister, userToRegisterState } from '../../state/userToRegister';
import { enrollerState } from '../../state/enroller';
import { retrieveUserStatus } from '../../api/institutionProxy';

interface Props {
  institutionID: string,
  isPaperlessWithoutAgoraID?: boolean,
}

type RegistrationSteps = 'create' | 'validation' | 'history';

function LayoutRegistration(props: Props) {
  const { institutionID, isPaperlessWithoutAgoraID } = props;
  const { agoraID } = useParams();
  const [user, setUser] = useState<UserRegistration>({
    firstName: '',
    lastName: '',
    email: '',
    pids: [],
    birthDate: '',
    mobileNumber: '',
    street: '',
    city: '',
    zipCode: '',
    country: '',
    institutions: [],
    institutionsPatient: [],
    locale: availableLocales[0].locale,
    validationType: '',
  });
  const [currentDisabledKeys, setDisabledKeys] = useState<DisabledKeys>([]);
  const [registrationStep, setRegistrationStep] = useState<RegistrationSteps>('create');
  const [userToRegister, setUserToRegister] = useRecoilState(userToRegisterState);
  const enroller = useRecoilValue(enrollerState);
  const userToRegisterIsStaffOnly = useRecoilValue(seletorUserToRegisterIsStaffOnly);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      userToRegister !== undefined
      && userToRegister.status.locale !== undefined
    ) {
      const { locale } = userToRegister.status;
      setUser((currentUser) => ({
        ...currentUser,
        locale,
      }));
    }
  }, [userToRegister]);

  useEffect(() => {
    if ((agoraID === undefined || agoraID.length === 0) && !isPaperlessWithoutAgoraID) {
      navigate('/');
    }
    return () => {};
  }, [agoraID]);

  useEffect(() => {
    const patientToRegister = (patientToVerify: UserToRegister) => (
      patientToVerify.status.institution_affiliated === true
      && patientToVerify.status.pre_registered === true
    );

    const verifyUserToRegisterStatus = async () => {
      if (
        userToRegister === undefined
        && window.authService !== undefined
      ) {
        const token = await window.authService.getAccessToken();
        const userToRegisterStatus = await retrieveUserStatus(agoraID, enroller.institutionID, token);
        if (
          patientToRegister({
            status: userToRegisterStatus,
          })
        ) {
          setUserToRegister({
            status: userToRegisterStatus,
          });
        } else {
          navigate('/');
        }
      } else if (
        userToRegister === undefined
        || !patientToRegister(userToRegister)
      ) {
        navigate('/');
      }
    };

    if (enroller !== undefined && !isPaperlessWithoutAgoraID) {
      verifyUserToRegisterStatus();
    }
  }, [userToRegister, enroller]);

  const handleCancel = () => {
    navigate('/');
  };

  const handleCancelValidation = () => {
    if (Object.keys(user).every((key) => user[key as keyof UserRegistration].length > 0)) {
      setRegistrationStep('validation');
    } else {
      handleCancel();
    }
  };

  const handleSubmit = (userSubmit: UserRegistration, disabledKeys: DisabledKeys) => {
    setUser(userSubmit);
    setDisabledKeys(disabledKeys);
    setRegistrationStep('validation');
  };

  const handleUpdate = () => {
    setRegistrationStep('create');
  };

  const handleSubmitPaperless = () => {
    navigate('/#usercreated');
  };

  const handleSubmitFullPaperless = () => {
    navigate('/#usercreated');
  };

  const handleSubmitRegistrationValidation = () => {
    if (
      !currentDisabledKeys.includes('pids')
      && userToRegisterIsStaffOnly === false
    ) {
      setRegistrationStep('history');
    } else {
      navigate('/#usercreated');
    }
  };

  return (
    <Content>
      <>
        {
          (
            registrationStep === 'create'
            && enroller !== undefined
          ) && (
            <Registration
              agoraID={agoraID !== undefined ? agoraID : ''}
              institutionID={institutionID}
              user={user}
              userEnroller={{
                ...enroller,
                initiale: enroller.initiale !== undefined ? enroller.initiale : '',
              }}
              currentDisabledKeys={currentDisabledKeys}
              onCancel={handleCancelValidation}
              onSubmit={handleSubmit}
              onSubmitPaperless={handleSubmitPaperless}
              isPaperlessWithoutAgoraID={isPaperlessWithoutAgoraID}
              onSubmitFullPaperless={handleSubmitFullPaperless}
            />
          )
        }
        {
          registrationStep === 'validation' && (
            <RegistrationValidation
              agoraID={agoraID !== undefined ? agoraID : ''}
              institutionID={institutionID}
              user={user}
              userEnroller={{
                ...enroller,
                initiale: enroller.initiale !== undefined ? enroller.initiale : '',
              }}
              disabledKeys={currentDisabledKeys}
              onUpdate={handleUpdate}
              onCancel={handleCancel}
              onSubmit={handleSubmitRegistrationValidation}
            />
          )
        }
        {
          (
            registrationStep === 'history'
            && !currentDisabledKeys.includes('pids')
          ) && (
            <TransferPatientHistory
              agoraID={agoraID !== undefined ? agoraID : ''}
              institutionID={institutionID}
              user={user}
              onCancel={handleCancel}
              onSubmit={handleCancel}
            />
          )
        }
      </>
    </Content>
  );
}

LayoutRegistration.defaultProps = {
  isPaperlessWithoutAgoraID: false,
};

LayoutRegistration.propTypes = {
  institutionID: PropTypes.string.isRequired,
  isPaperlessWithoutAgoraID: PropTypes.bool,
};

export default LayoutRegistration;
