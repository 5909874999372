import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PageLoader from 'components/layout/pageLoader/PageLoader';
import { retrieveUserStatus } from '../../api/institutionProxy';
import { enrollerState } from '../../state/enroller';
import { userToRegisterState } from '../../state/userToRegister';

function LayoutUser() {
  const navigate = useNavigate();
  const { agoraID } = useParams<string>();
  const setStateUserToRegister = useSetRecoilState(userToRegisterState);
  const enroller = useRecoilValue(enrollerState);

  useEffect(() => {
    const redirect = async (): Promise<void> => {
      try {
        if (
          window.authService !== undefined
          && agoraID !== undefined
        ) {
          const token = await window.authService.getAccessToken();
          const userStatus = await retrieveUserStatus(agoraID, enroller.institutionID, token);

          if (userStatus.unknown) {
            throw new Error('unknown user');
          }

          setStateUserToRegister({
            status: userStatus,
          });

          if (
            userStatus.institution_affiliated
            && userStatus.pre_registered
          ) {
            navigate(`/${agoraID}/enrollment`);
          } else if (
            userStatus.institution_affiliated
            && userStatus.pre_registered === false
            && userStatus.roles?.includes('patient')
          ) {
            navigate(`/${agoraID}/user`);
          } else if (
            userStatus.institution_affiliated === false
            || (
              userStatus.institution_affiliated
              && userStatus.roles?.includes('personnel')
              && userStatus.roles?.includes('patient') === false
            )
          ) {
            navigate(`/${agoraID}/affiliation`);
          } else {
            navigate('/#unknownuser');
          }
        } else {
          navigate('/#unknownuser');
        }
      } catch {
        navigate('/#unknownuser');
      }
    };

    if (enroller !== undefined) {
      redirect();
    }
  }, [enroller]);

  return <PageLoader />;
}

export default LayoutUser;
